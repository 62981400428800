<template>
  <el-card class="box-card">
  <div class="depart-box">
    <!--左侧树形-->
    <div class="left-tree">
      <el-tree :data="treedata"
               accordion
               default-expand-all
               empty-text="暂无数据"
               :props="defaultProps"
               @node-click="handleNodeClick"></el-tree>
    </div>

    <div class="right-table">
      <div>
        <el-form ref="form" :inline="true" :model="queryParams" label-width="80px">
          <el-form-item label="关键字">
            <el-input style="width: 300px" clearable v-model="queryParams.searchName" placeholder="请输入账号/身份证号/手机号/姓名"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="searchList">查询</el-button>
          </el-form-item>
          <el-button style="float: right" type="primary" @click="userAddorEdit">新增用户</el-button>
        </el-form>

      </div>
      <el-table v-loading="loading" style="width: 100%;margin-top: 10px" :data="tableData" border :header-cell-style="headerCellStyle">
        <template slot="empty">
          <IsEmpty />
        </template>
        <el-table-column type="index" label="序号" width="50" align="center" />
        <el-table-column prop="accountNumber" label="账号" align="center"  />
        <el-table-column prop="name" label="姓名"  align="center"  />
        <el-table-column prop="idNumber" label="身份证号码"  align="center"  />
        <el-table-column prop="contactPhone" label="手机号"  align="center"  />
        <el-table-column prop="departmentName" label="所在部门"  align="center"  />
        <el-table-column prop="roleName" label="所属角色"  align="center"  />
        <el-table-column prop="zhanghao" label="账号状态"  align="center" >
          <template slot-scope="scope">
            <span>{{['禁用', '启用'][scope.row.status]}}</span>
          </template>
          </el-table-column>
        <el-table-column prop="address6" label="操作"  width="250" align="center" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" @click="toDetail(scope.row)">查看</el-button>
            <el-button type="text" @click="userAddorEdit(scope.row)">编辑</el-button>
            <el-button type="text" style="color: #FF5A59" v-if="scope.row.status === 1" @click="handlerStopUserCount(scope.row, 0)">停用</el-button>
            <el-button type="text" v-else @click="handlerStopUserCount(scope.row, 1)">启用</el-button>
            <el-button type="text" @click="repassword(scope.row)">重置密码</el-button>
<!--            <el-button type="text" @click="delItem(scope.row)">删除</el-button>-->
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParams.pageNum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="queryParams.pageSize"
        layout="total, sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
    <userAddorEditDilog v-if="userAddorEditVisible" @reloadPage="reloadPage" :userItem="userItem" :treedata="treedata" :userAddorEditVisible.sync="userAddorEditVisible"/>
    <userInfoDetailDilog :detailData="detailData" :userInfoDetailVisible.sync="userInfoDetailVisible"/>
    <repasswordDilog :repasswordId="repasswordId" :repasswordVisible.sync="repasswordVisible"/>
    <stopOrEnableUserCount @reloadPage="reloadPage"  :changeCountParams="changeCountParams" :stopOrEnableUserVisible.sync="stopOrEnableUserVisible"/>
  </div>
  </el-card>
</template>

<script>
import userAddorEditDilog from "./components/userAddorEditDilog";
  import userInfoDetailDilog from "./components/userInfoDetailDilog";
  import repasswordDilog from "./components/repasswordDilog"
  import stopOrEnableUserCount from "./components/stopOrEnableUserCount";
  import { getContent } from '@/utils/index'
  import { SIGNOPTIONS } from '@/data/dataDictionary'
import { departmentTreeList, getUserList, delUser } from "@/api/userManage"
  export default {
    name: "unitApproved",
    components: {
      userAddorEditDilog,
      userInfoDetailDilog,
      repasswordDilog,
      stopOrEnableUserCount
    },
    data() {
      return {
        SIGNOPTIONS,
        detailData: {}, //查看详情
        repasswordId: '', //重置密码数据ID
        changeCountParams: {}, //启用、停用账号参数
        userItem: {}, //编辑选中的数据
        userAddorEditVisible: false, //新增或者编辑弹框
        userInfoDetailVisible: false, //查看详情
        repasswordVisible: false, //重置密码
        stopOrEnableUserVisible: false, //停用/启用账号
        loading: false,
        total: 0,
        tableData: [],
        queryParams: { //查询参数
          pageNum: 1,
          pageSize: 10,
          searchName: '' //关键字
        },
        treedata: [],
        defaultProps: {
          id: 'id'
        }
      }
    },
    mounted() {
      this.getDepartmentTreeList()
      this.getList()
    },
    methods: {
      getContent,
      reloadPage() { //重新加载页面
        this.getDepartmentTreeList()
        this.getList()
      },
      searchList() {
        this.queryParams.pageNum = 1
        this.getList()
      },
      /*查询参数*/
      searchFromData(data) {
        this.queryParams = {...this.queryParams, ...data}
      },
      handleSizeChange(pageSize) {
        this.queryParams.pageSize = pageSize
        this.queryParams.pageNum = 1
        this.getList()
      },
      handleCurrentChange(pageNum) {
        this.queryParams.pageNum = pageNum
        this.getList()
      },
      //  查看详情
      toDetail(item) {
        this.detailData = item
        this.userInfoDetailVisible = true
      },
      //  新增或者编辑弹框
      userAddorEdit(item) {
        if(item && item.id) {
          this.userItem = item
        }else {
          this.userItem = {
            idNumber: '', //身份证号码
            name: '', //真实姓名
            contactPhone: '',//电话
            accountNumber: '', //用户名
            password: '', //密码
            comfirmPassword: '', //确认密码
            treeParams: [], //上级部门参数 要删除
            departmentId: '', //部门 id
            status: 1 ,//账号状态 1启用 0禁用
            unitName: '',//所属单位名字 需删除
            unitId: '',//所属单位ID
            jurisdiction: '',
            jurisdictionName: '',
            roleId: ''
          }
        }

        this.userAddorEditVisible = true
      },
      //删除
      delItem(item) {
        this.$confirm(`此操作将删除"${item.accountNumber}"账号, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          delUser({id: item.id}).then(res => {
            if(res.state === 0) {
              this.$message.success(res.data)
              this.getList()
            }
          })

        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      //重置密码
      repassword(item) {
        this.repasswordId = item.id
        this.repasswordVisible = true
      },
      //停用/启用账号
      handlerStopUserCount(item, status) {
        this.changeCountParams = {
          status: status,
          id: item.id
        }
        if(!item.roleName) return this.$message.warning('所属角色不存在，请重新选择所属角色')
        this.stopOrEnableUserVisible = true
      },
      //树选择
      handleNodeClick(data) {
        console.log(data);
        this.queryParams = data
        this.getList()
      },
    //  获取数数据
      async getDepartmentTreeList() {
        const res = await departmentTreeList()
        if(res.state === 0 && res.data[0] !== null) {
          this.treedata = res.data
        }else {
          this.treedata = []
        }
      },
    //  获取列表数据
     async getList() {
       this.loading = true
       try {
         const res = await getUserList(this.queryParams)
         this.loading = false
         this.tableData = res.data.rows
         this.total = res.data.total
       }catch (e) {
         this.loading = false
       }
     }
    }
  }
</script>

<style lang="scss" scoped>
  .box-card {
    min-height: calc(100% - 10px);
  }
  .depart-box {
    background-color: #FFFFFF;
    height: calc(100% - 10px);
    .left-tree {
      padding: 30px 0 0 26px;
      width: 270px;

      box-sizing: border-box;
      display: inline-block;
      float: left;
    }
    .right-table {
     display: inline-block;
      box-sizing: border-box;
      padding: 50px 40px 40px 40px;
      width: calc(100% - 270px);
      position: relative;
    }
    .right-table:after {
      content: '';
      height: 100vh;
      border-left: 1px solid #EEEEEE;
      position: absolute;
      top: -20px;
      left: 0;
    }
  }

</style>
